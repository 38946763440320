<template>
  <div>
    <a-popover  v-model="visible" trigger="click" title="配置字段" placement="left">
      <template #content>
        <div class="popover-content" v-if="configuration.id">
          <a-descriptions bordered>
            <a-descriptions-item label="配置ID">{{ configuration.id }} </a-descriptions-item>
            <a-descriptions-item label="配置标识符"> {{ configuration.identifier }} </a-descriptions-item>
            <a-descriptions-item label="配置描述" v-if="configuration.description">{{
              configuration.description.defaultMessage ? configuration.description.defaultMessage : '暂无'
            }}</a-descriptions-item>
            <a-descriptions-item label="配置项定义唯一标识"> {{ configuration.encode }} </a-descriptions-item>
            <a-descriptions-item label="属性类型">
              <span>{{ propertyType(configuration) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="编辑方式">
              <span>{{ propertyEditMode(configuration) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="默认值">
              <span>{{ configuration.type.defaultValue || '无' }}</span>
            </a-descriptions-item>
            <template v-if="configuration.type.editMode == $constRepository.productFormItem.editMode.input">
              <a-descriptions-item label="范围">
                <span v-if="configuration.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                  `${configuration.type.range.min}-${configuration.type.range.max}`
                }}</span>
                <span v-else>无</span>
              </a-descriptions-item>
            </template>
            <template v-if="configuration.type.editMode == $constRepository.productFormItem.editMode.select">
              <a-descriptions-item label="选项">
                <p v-for="(option, index) in configuration.type.options" :key="index">
                  {{ `${option.value}:${option.description.defaultMessage}` }}
                </p>
              </a-descriptions-item>
            </template>
          </a-descriptions>
        </div>
      </template>
      <a-button type="link" :loading="loading" @click="handleClick">查看</a-button>
    </a-popover>
  </div>
</template>

<script>
export default {
  name: 'ProductConfigurationPopover',
  props: ['id'],
  data() {
    return {
      configuration: {},
      visible: false,
      loading: false,
      metaData: this.$store.state.metaData,
    };
  },
  methods: {
    async getConfiguration() {
      const res = await this.$apiManager.productConfiguration.getProductConfigurationById(this.id);
      this.configuration = res.data;
    },
    async handleClick() {
      this.loading = true;
      try {
        await this.getConfiguration();
        this.visible = true;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const propertyName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return propertyName ? propertyName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => {
        const modeName = this.$constRepository.productFormItem.editModeText[property.type.editMode];
        return modeName || '';
      };
    },
  },
};
</script>
