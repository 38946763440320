<template>
  <div>
    <a-modal
      :visible="visible"
      title="配置选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="configurationItems.length"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option
          v-for="configurationItem in configurationItems"
          :label="configurationItem.identifier"
          :key="configurationItem.id"
          >{{ configurationItem.identifier }}</a-select-option
        >
      </a-select>
      <div class="property-operations">
        <div></div>
        <div class="data-filter">
          <a-input
            clearable
            placeholder="请输入标识符或备注内容"
            v-model="searchKeyword"
            class="search-keyword"
            @keyup.enter.native="listProductConfiguration"
          ></a-input>
          <a-button class="search-btn" type="primary" @click="listProductConfiguration()">搜索</a-button>
        </div>
      </div>
      <a-table
        size="middle"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        :columns="this.$constRepository.productConfiguration.columns"
        :data-source="tableDate"
        class="property-table"
        rowKey="id"
      >
        <template slot="description" slot-scope="scope">{{
          scope.defaultMessage ? scope.defaultMessage : '暂无'
        }}</template>
        <template slot="action" slot-scope="scope">
          <ProductConfigurationPopover :id="scope.id"></ProductConfigurationPopover>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';
import ProductConfigurationPopover from './ProductConfigurationPopover';

export default {
  name: 'ProductConfigurationSelector',
  components: { ProductConfigurationPopover },
  data() {
    return {
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: Function,
      },
      searchKeyword: '',
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableDate: [],
      configurationItems: [],
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    async listProductConfiguration() {
      const response = await this.$apiManager.productConfiguration.getProductConfigurationItems({
        unpaged: true,
        searchKey: this.searchKeyword,
      });
      this.tableDate = response.data.elements;
      this.configurationItems = !this.configurationItems.length ? response.data.elements : this.configurationItems;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      await this.listProductConfiguration();
      // 将已选择置于表格前;
      const target = this.selectedRowKeys.map((id) => this.tableDate.find((item) => item.id === id));
      this.tableDate = lodash.uniqBy([...target, ...this.tableDate], 'id');
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const params = this.selectedRowKeys.map((id) => this.configurationItems.find((item) => item.id === id));
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
  },

};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.popover-content {
  max-height: 400px;
  overflow-y: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
}
.property-table {
  // margin-top: 1%;
}
.pagination {
  margin-top: 3%;
}
</style>
