<template>
  <div>
    <a-popover v-model="visible" trigger="click" title="服务字段" placement="left">
      <template #content>
        <div class="popover-content" v-if="service.id">
          <a-descriptions bordered>
            <a-descriptions-item label="服务项标识符">
              {{ service.identifier }}
            </a-descriptions-item>
            <a-descriptions-item label="服务描述">
              {{ service.description.defaultMessage }}
            </a-descriptions-item>
            <a-descriptions-item label="超时时间">
              {{ service.timeout }}
            </a-descriptions-item>
          </a-descriptions>
          <template v-if="service.parameters.length > 0">
            <h3 style="margin-top: 1rem">参数项</h3>
            <a-descriptions
              v-for="parameter in service.parameters"
              :key="parameter.identifier"
              bordered
              style="margin-top: 1%"
            >
              <a-descriptions-item label="参数项标识符"> {{ parameter.identifier }} </a-descriptions-item>
              <a-descriptions-item label="参数项名称">
                {{ parameter.description.defaultMessage }}
              </a-descriptions-item>
              <a-descriptions-item label="属性类型">
                <span>{{ propertyType(parameter) }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="编辑方式">
                <span>{{ propertyEditMode(parameter) }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="默认值">
                <span>{{ parameter.type.defaultValue || '无' }}</span>
              </a-descriptions-item>
              <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.input">
                <a-descriptions-item label="范围">
                  <span v-if="parameter.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                    `${parameter.type.range.min}-${parameter.type.range.max}`
                  }}</span>
                  <span v-else>无</span>
                </a-descriptions-item>
              </template>
              <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.select">
                <a-descriptions-item label="选项">
                  <p v-for="(option, index) in parameter.type.options" :key="index">
                    {{ `${option.value}:${option.description.defaultMessage}` }}
                  </p>
                </a-descriptions-item>
              </template>
            </a-descriptions>
          </template>
        </div>
      </template>
      <a-button type="link" :loading="loading" @click="handleClick">查看</a-button>
    </a-popover>
  </div>
</template>

<script>
export default {
  name: 'ProductServicePopover',
  props: ['id'],
  data() {
    return {
      service: {},
      visible: false,
      loading: false,
      metaData: this.$store.state.metaData,
    };
  },
  methods: {
    async getService() {
      const res = await this.$apiManager.productService.getproductServiceById(this.id);
      this.service = res.data;
    },
    async handleClick() {
      this.loading = true;
      try {
        await this.getService();
        this.visible = true;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const propertyName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return propertyName ? propertyName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => {
        const modeName = this.$constRepository.productFormItem.editModeText[property.type.editMode];
        return modeName || '';
      };
    },
  },
};
</script>
